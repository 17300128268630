.about {
  width: 90%;
  margin: 5em auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._img {
  margin-right: 10em;
  width: 100%;
  height: 20em;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background-size: contain;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(132,74,194,0.3);
  position: relative;
}
._content_wrapper {
  width: 100%;
}
.contact-btn {
  margin-top: 20px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background-color: rgba(132,74,194,0.3);
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(132,74,194,0.7);
  display: inline-block;
}
.contact-btn:hover {
  text-decoration: none;
  background-color: rgba(132,74,194,0.8);
  animation: zoom-in-zoom-out 1s ease;
  transform: scale(1.005, 1.005);
  transition: all 0.5s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}
.contact-btn:not(:hover){
  transition: all 0.5s ease;
}
@media (max-width: 1024px) {
  .about{
    width: 80%;
  }
  ._img {
    margin: 2em;
  }
}
@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }
}