.contact {
  display: flex;
  width: 90%;
  margin: auto;
  margin-bottom: 5em;
}
._form_wrapper {
  display: flex;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(132,74,194,0.3);
  width: 50%;
  height: 28em;
  padding: 2em;
}
.submit-btn {
  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  background-color: rgba(132,74,194,0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(132,74,194,0.7);
  padding: 10px;
  border-radius: 10px;
}
.submit-btn:hover {
  margin-right: 20px;
  text-decoration: none;
  background-color: rgba(132,74,194,0.8);
  animation: zoom-in-zoom-out 1s ease;
  transform: scale(1.005, 1.005);
  transition: all 0.5s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}
.submit-btn:not(:hover){
  transition: all 0.5s ease;
}
.contact_text{
  width: 50%;
}
.contact_msg {
  font-size: 4rem;
  margin: 5rem;
}
@media (max-width: 1024px) {
  .contact{
    width: 80%;
  }
  ._form_wrapper {
    width: 300px;
    height: auto;
    margin-left: 4em;
    margin-right: 4em;
    padding: 2em;
  }
}
@media (max-width: 768px) {
  .contact {
    width: 80%;
    margin-bottom: 7em;
    flex-direction: column;
  }
  ._form_wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 2em;
  }
  .contact {
    flex-direction: column;
  }
  .contact_msg{
    display: none;
  }
}