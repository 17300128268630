.project {
  width: 90%;
  max-width: 60em;
  margin: 2em auto;
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(132,74,194,0.3);
}
.project-reverse {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.__img_wrapper {
  width: 40%;
  display: flex;
  justify-content: center;
}
.__img_wrapper > img {
  width: 40%;
  max-width: 30em;
}
.__content_wrapper {
  width: 60%;
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;

}
.works_title{
  margin: auto;
  text-align: center;
  font-size: 4rem;
}
.title {
  font-size: 1.5rem;
  margin: 0;
}
.description {
  font-size: 1rem;
  width: 80%;
}

a{
  text-decoration: none;
  color: inherit;
}

@media (max-width: 1024px) {
  .__content_wrapper {
    width: 50%;
  }
  .works_title{
    font-size: 3rem;
  }
}
@media (max-width: 768px) {
  .project {
    width: 80%;
    flex-direction: column;
  }
  .__img_wrapper {
    width: 340px;
  }
  .__content_wrapper {
    width: 70%;
  }
  .works_title{
    font-size: 2rem;
  }
}

.project:hover{
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  background: rgba(132,74,194,0.3);
  transition: all 0.5s ease;
}

.project:not(:hover){
  transition: all 0.5s ease;
}